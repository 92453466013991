import { useEffect } from 'react';
import * as React from 'react';
import type { HeadFC, PageProps } from 'gatsby';

import { prefix, URLS } from '../utils/urls';

const IndexPage: React.FC<PageProps> = () => {
  useEffect(() => {
    window.location.replace(`${prefix}${URLS.INFCON_2024__HOME}`);
  }, []);

  return <></>;
};

export default IndexPage;

export const Head: HeadFC = () => <title></title>;
